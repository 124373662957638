<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :label="label"
    item-text="UF_NAME"
    item-value="ID"
    :disabled="disabled"
    :required="required"
    :rules="rules"
    :dense="dense"
    :search-input.sync="search"
    return-object
    clearable
    color="teal"
    @change="change($event)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'Autocomplete',
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    groupId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    search: '',
    items: [],
    timer: null,
  }),
  watch: {
    search(val) {
      const findItem = this.items.find((item) => item.UF_NAME === val);
      if (findItem) return false;

      if (val && val.length >= 3) {
        if (this.timer) clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.getGoods(val, this.groupId);
        }, 500);
      } else if (!val) {
        this.timer = setTimeout(() => {
          this.getGoods(null, this.groupId);
        }, 500);
      }

      return val;
    },
  },
  methods: {
    getGoods(val, groupId) {
      const params = {
        order: {
          UF_SORT: 'ASC',
        },
        filter: {
          UF_ACTIVE: '1',
          UF_GROUP: groupId,
        },
        select: ['*'],
        limit: 100,
        offset: 0,
      };

      if (val) {
        params.filter['%=UF_NAME'] = `%${val}%`;
      }

      this.$store.dispatch('getGoods', { params }).then((response) => {
        this.items = response.data.data.result;
      });
    },
    change(val) {
      const value = (val) ? val[this.itemValue] : null;

      this.$emit('change', value);
      this.$emit('input', value);
      this.$emit('inputObject', val);
    },
  },
  mounted() {
    this.getGoods(null, this.groupId);
  },
};
</script>
